import React from "react";
import cx from "classnames";

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4 bg-brand hover:bg-brand3 text-base text-white font-bold uppercase rounded shadow-md",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

export default StyledButton;
