import React from "react";
import Layout2 from "../components/layout2";
import SEO from "../components/seo";
import StyledButton from "../components/styledButton";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import GoogleMap from "../components/GoogleMap";

const Kontakt = ({ data }) => {
  const kontaktData = data.kontaktImg.childImageSharp.fluid;
  return (
    <Layout2>
      <SEO title="Kontakt" />

      <div className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-6 flex flex-col items-start">
        <div className="w-3/4 md:w-1/2 mt-8">
          <h1 className="text-4xl md:text-5xl text-brand3">Kontakt</h1>
        </div>
        <div
          className="w-full xl:w-1/2 mt-10 px-6 py-4 "
          style={{
            boxShadow:
              "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)",
          }}
        >
          <div className="flex justify-between">
            <p>
              <a
                href="tel:0562210023"
                className="text-lg md:text-3xl text-brand hover:text-brand3 font-semibold text-center"
              >
                056 221 00 23
              </a>
            </p>
            <a href="tel:0562210023">
              <StyledButton>Anrufen</StyledButton>
            </a>
          </div>
          <div className="flex justify-between mt-6">
            <p>
              <a
                href="mailto:info@praxis-am-laegern.ch"
                className="text-lg md:text-3xl text-brand hover:text-brand3 font-semibold text-center"
              >
                info@praxis-am-laegern.ch
              </a>
            </p>
            <a href="mailto:info@praxis-am-laegern.ch">
              <StyledButton>Email</StyledButton>
            </a>
          </div>
        </div>
        <div className="w-full">
          <h3 className="mt-10 mb-6 text-3xl text-brand">Adresse</h3>
          <p>
            Praxis am Lägern
            <br />
            Dr. med. dent. Aleksandar Stojanović
            <br />
            eidg. dipl. Zahnarzt - SSO Mitglied
            <br />
            <span className="font-semibold">
              Kirchweg 20
              <br />
              5420 Ehrendingen
            </span>
          </p>
        </div>
        <div className="w-full">
          <h3 className="mt-10 mb-6 text-3xl text-brand">Karte</h3>
          <GoogleMap />
        </div>
      </div>
      <Img fluid={kontaktData} className="w-full my-8" />
    </Layout2>
  );
};

export default Kontakt;

export const query = graphql`
  query {
    kontaktImg: file(relativePath: { eq: "call-us.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
