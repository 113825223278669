import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./marker";

const defaultProps = {
  center: {
    lat: 47.498046,
    lng: 8.343928,
  },
  zoom: 17,
};

const GoogleMap = () => {
  const getMapOptions = (maps) => {
    return {
      disableDefaultUI: true,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
      ],
    };
  };

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDbDw_dDCV2G4_2iylQddPQxOju66nzaKc" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={getMapOptions}
      >
        <Marker
          lat={47.498046}
          lng={8.343928}
          name={"Praxis am Lägern"}
          color="red"
        />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
